<template>
  <div
    class="subscription-header-banner"
    data-theme="light"
  >
    <div class="subscription-header-banner__wrapper">
      <p class="subscription-header-banner__title t t--2">
        Попробуйте Академику Pro прямо сейчас
      </p>

      <UiPicture
        v-if="isDesktop"
        :data="barImages"
        width="72"
        height="72"
        class="subscription-header-banner__icon"
      />

      <UiButton
        size="extra-big"
        theme="senary"
        :description="computedBtnContent.description"
        class="subscription-header-banner__button"
        @click="openPaymentModal"
      >
        {{ computedBtnContent.text }}
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useStorage } from '@vueuse/core'
  import UiPicture from '@academica-box/components/Media/UiPicture/UiPicture.vue'
  import UiButton from '@academica-box/components/Buttons/UiButton/UiButton.vue'
  import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
  import { useUserStore } from '@academica-box/composables/store/user'
  import { SmartComponents, useSmartComponentsStore } from '@/store/smartComponents'

  defineEmits(['click:pay'])

  const viewport = useViewport()
  const isSubscribeOpenModal = useStorage('is_subscribe_open_modal', false)
  const isDesktop = computed(() => viewport.isGreaterOrEquals('lg'))
  const subscribesStore = useSubscribesStore()
  const { isTrialAvailable, activeSubscription } = storeToRefs(subscribesStore)
  const smartComponentsStore = useSmartComponentsStore()
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  const barImages = {
    md: {
      avif: {
        '1x': 'https://cdn.skillbox.pro/wbd-front/academica-static/general/promo-banner/black-friday/center-picture-new-md@1x.avif',
        '2x': 'https://cdn.skillbox.pro/wbd-front/academica-static/general/promo-banner/black-friday/center-picture-new-md@2x.avif'
      },
      webp: {
        '1x': 'https://cdn.skillbox.pro/wbd-front/academica-static/general/promo-banner/black-friday/center-picture-new-md@1x.webp',
        '2x': 'https://cdn.skillbox.pro/wbd-front/academica-static/general/promo-banner/black-friday/center-picture-new-md@2x.webp'
      },
      img: {
        '1x': 'https://cdn.skillbox.pro/wbd-front/academica-static/general/promo-banner/black-friday/center-picture-new-md@1x.png',
        '2x': 'https://cdn.skillbox.pro/wbd-front/academica-static/general/promo-banner/black-friday/center-picture-new-md@2x.png'
      },
    },
  }

  const computedBtnContent = computed(() => {
    let btnContent: {
      text: string;
      description: string | undefined;
    } = {
      text: 'Оформить подписку',
      description: `за ${activeSubscription.value?.base_price} ₽ в месяц`
    }

    if (isTrialAvailable.value) {
      btnContent = {
        text: `Попробовать подписку за ${activeSubscription.value?.trial_price} ₽`,
        description: `Далее ${activeSubscription.value?.base_price} ₽ в месяц`
      }
    }

    return btnContent
  })

  const openPaymentModal = () => {
    if (!user?.value) {
      isSubscribeOpenModal.value = true
      userStore.login()
    } else {
      smartComponentsStore.set(SmartComponents.PaymentModal, { isShow: true })
    }
  }
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia/index' as *;
  @use '@academica-box/utils/styles/mixins/UiZIndex/index' as *;
  @use '@academica-box/styles/UiContainer/variables' as *;

  .subscription-header-banner {
    width: 100%;
    color: var(--accent-always-black-color);
    background: linear-gradient(283deg, #d6b7fd -0.8%, #c3ddfd 13.58%, rgba(243, 234, 255) 54.43%, #c3ddfd 88.26%, #d6b7fd 105.25%);
  }

  .subscription-header-banner__wrapper {
    display: flex;
    align-items: center;
    width: $ui-lg-c;
    margin-right: auto;
    margin-left: auto;

    @include media(xl) {
      width: $ui-xl-c;
    }

    @include media(xxl) {
      width: $ui-xxl-c;
    }
  }

  .subscription-header-banner__title {
    margin-right: auto;
    margin-bottom: 0;
  }

  .subscription-header-banner__icon {
    width: 72px;
    height: 72px;
    margin-right: 30px;
    padding: 10px;
  }
</style>
