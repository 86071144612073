<template>
  <div :class="layoutClasses">
    <div
      ref="headerWrapper"
      class="layout__header-wrapper"
      :class="{'layout__header-wrapper--hide' : hideHeaderWrapper}"
    >
      <UiPromoBannerSmart
        ref="banner"
        class="layout__banner"
        @click="(value: string) => sendAcademicaClickBanner({link: value})"
        @visible="sendAcademicaShowBanner"
      />

      <UiHeaderSmart
        ref="header"
        class="layout__header"
        :class="{'layout__header--hide': hideHeader}"
        @update:search-field-dropdown="isSearchFieldDropdownOpen = $event"
        @visible:banner="sendAcademicaShowMenuBanner"
        @click:banner="(value: string) => sendAcademicaClickMenuBanner({link: value})"
        @visible:navigation="sendAcademicaShowMenu('header', {})"
        @click:search-field="sendAcademicaClickSearch"
        @update:search="sendAcademicaEnteringTextIntoSearch"
        @submit:search="sendAcademicaViewSearchResult"
        @visible:user-menu="sendAcademicaShowBurgerMenu"
        @click:menu-item="(value: string) => sendAcademicaClickBurgerMenu({link: value})"
        @click:direction="(index) => {
          sendAcademicaClickMenu('header', {
            selected_direction_id: directionsWithQuery[index]?.slug || '',
          })
        }"
        @click:theme="isThemeModalOpen = true"
      />

      <SubscriptionHeaderBanner
        v-if="isSubscriptionPage && !isMobile"
        ref="bar"
        class="layout__subscription-header-banner"
        :class="{'layout__subscription-header-banner--transform': transformSubscribeBanner}"
      />
    </div>

    <div class="layout__content">
      <div
        v-if="breadcrumbs.length"
        class="layout__breadcrumbs-wrapper"
      >
        <UiBackButton
          v-if="isMobile"
          :link="previousPage"
          class="layout__breadcrumbs"
        >
          Главная
        </UiBackButton>

        <UiBreadcrumbs
          v-else
          :breadcrumbs="breadcrumbs"
          class="layout__breadcrumbs"
        />
      </div>

      <slot />
    </div>

    <UiFooterSmart
      class="layout__footer"
      @visible:navigation="sendAcademicaShowMenu('footer', {})"
      @click:direction="(index) => {
        sendAcademicaClickMenu('footer', {
          selected_direction_id: directionsWithQuery[index]?.slug || '',
        })
      }"
      @visible:contacts="sendAcademicaShowContactBlock"
      @click:contacts="sendAcademicaClickContactBlock"
      @click:social="handleSocialClick"
    />

    <div class="layout__navigation">
      <ProjectTabBar
        v-if="isMobile"
        @click:theme="isThemeModalOpen = true"
      />
    </div>

    <UiThemeModal
      v-if="isThemeModalOpen"
      @click:theme="handleThemeChangeClick"
      @click:close="isThemeModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
  import { ref, type VNodeRef } from 'vue'
  import { storeToRefs } from 'pinia'

  import UiBreadcrumbs from '@academica-box/components/Blocks/UiBreadcrumbs/UiBreadcrumbs.vue'
  import UiBackButton from '@academica-box/components/Buttons/UiBackButton/UiBackButton.vue'
  import UiHeaderSmart from '@academica-box/components/Smarts/UiHeader.smart/UiHeader.smart.vue'
  import UiPromoBannerSmart from '@academica-box/components/Smarts/UiPromoBanner.smart/UiPromoBanner.smart.vue'
  import UiFooterSmart from '@academica-box/components/Smarts/UiFooter.smart/UiFooter.smart.vue'
  import UiThemeModal from '@academica-box/components/Modals/UiThemeModal/UiThemeModal.vue'

  import { useCommonStore } from '@skillbox/academica-box/src/composables/store/common'
  import { useDirectionsStore } from '@academica-box/composables/store/directions'
  import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
  import { usePromoStore } from '@academica-box/composables/store/promo'
  import { useSearchStore } from '@academica-box/composables/store/search'
  import { subscribeProMock } from '@academica-box/mocks'

  import type { ThemeTypes } from '@academica-box/plugins/theme'
  import SubscriptionHeaderBanner from '@/components/Subscription/SubscriptionHeaderBanner.vue'
  import ProjectTabBar from '@/components/ProjectTabBar.vue'

  import { useBreadcrumbsStore } from '@/store/breadcrumbs'

  import {
    sendAcademicaShowMenu,
    sendAcademicaClickMenu,
    sendAcademicaShowBurgerMenu,
    sendAcademicaClickBurgerMenu,
    sendAcademicaShowContactBlock,
    sendAcademicaClickContactBlock,
    sendAcademicaClickSocialVk,
    sendAcademicaClickSocialTg,
    sendAcademicaClickSearch,
    sendAcademicaEnteringTextIntoSearch,
    sendAcademicaViewSearchResult,
    sendAcademicaShowBanner,
    sendAcademicaShowMenuBanner,
    sendAcademicaClickMenuBanner,
    sendAcademicaClickBanner
  } from '@/pages/analytics'

  const route = useRoute()
  const viewport = useViewport()
  const { $theme } = useNuxtApp()

  const commonStore = useCommonStore()
  const directionsStore = useDirectionsStore()
  const { directionsWithQuery } = useDirectionsStore()
  const breadcrumbsStore = useBreadcrumbsStore()
  const { breadcrumbs } = storeToRefs(breadcrumbsStore)
  const promoStore = usePromoStore()
  const { promo } = storeToRefs(promoStore)
  const searchStore = useSearchStore()
  const subscribesStore = useSubscribesStore()
  const { isTrialAvailable } = storeToRefs(subscribesStore)

  const previousPage = ref('/')
  const isSearchFieldDropdownOpen = ref(false)
  const header = ref<VNodeRef | null>(null)
  const hideHeader = ref(false)
  const lastScrollTop = ref(0)
  const headerWrapper = ref<VNodeRef | null>(null)
  const hideHeaderWrapper = ref(false)
  const transformSubscribeBanner = ref(false)
  const isThemeModalOpen = ref(false)

  if (breadcrumbs.value.length) {
    useSchemaOrg([
      defineBreadcrumb({
        itemListElement: breadcrumbs.value.map((breadcrumb) => {
          return {
            name: breadcrumb.title,
            id: breadcrumb.link
          }
        })
      })
    ])
  }

  const isMobile = computed(() => viewport.isLessThan('lg'))

  const isSubscriptionPage = computed(() => {
    return route.path.startsWith('/pro')
  })

  const layoutClasses = computed(() => {
    return [
      'layout',
      {
        'layout--with-promo-banner': isTrialAvailable || promo.value?.header_banner,
        'layout--with-subscribe-banner': subscribeProMock().banners.main && promo.value?.header_banner,
        'layout--header-wrapper-hide': hideHeaderWrapper.value,
        'layout--header-hide': hideHeader.value,
        'layout--header-search-field-open': isSearchFieldDropdownOpen.value
      }
    ]
  })

  const onScrollHeaderHide = () => {
    const HEADER_HEIGHT = 64

    if (isSearchFieldDropdownOpen.value) {
      return
    }

    const scroll = document.documentElement.scrollTop
    let safeGap = 4

    if (isMobile.value && header?.value?.$el.offsetHeight !== HEADER_HEIGHT) {
      safeGap = 118 // 118 - mobile header height with open search field
    }

    if (isMobile.value) {
      if (scroll > lastScrollTop?.value + safeGap) {
        hideHeaderWrapper.value = true
      } else if (isMobile.value && scroll < lastScrollTop?.value - safeGap) {
        hideHeaderWrapper.value = false
      }
    } else if (scroll > lastScrollTop?.value + safeGap) {
      hideHeader.value = true
      transformSubscribeBanner.value = true
    } else if (!isMobile.value && scroll < lastScrollTop?.value - safeGap) {
      hideHeader.value = false
      transformSubscribeBanner.value = false
    }

    lastScrollTop.value = scroll <= 50 ? 50 : scroll // 50 - safe scroll area at the top of the page
  }

  const handleSocialClick = (value: string) => {
    if (value === 'ВКонтакте' || value === 'VK') {
      sendAcademicaClickSocialVk({})
    }

    if (value === 'Telegram') {
      sendAcademicaClickSocialTg({})
    }
  }

  const handleThemeChangeClick = (value: ThemeTypes) => {
    $theme.postTheme(value)
  }

  onMounted(() => {
    window.addEventListener('scroll', onScrollHeaderHide)
  })

  onDeactivated(() => {
    window.removeEventListener('scroll', onScrollHeaderHide)
  })

  await useAsyncData(
    'getDirections',
    () => directionsStore.getDirections()
  )

  useAsyncData(
    'getActiveSubscriptions',
    () => subscribesStore.getActiveSubscriptions()
  )

  useAsyncData(
    'getSuggestionsData',
    () => searchStore.getSuggestionsData()
  )

  await useAsyncData(
    'getFooter',
    () => commonStore.getFooter()
  )
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;
  @use '@academica-box/utils/styles/mixins/UiZIndex' as *;
  @use '@academica-box/styles/UiContainer/mixin' as *;

  @mixin transition {
    transition: all .25s ease-in-out;
  }

  .layout {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100vw;
    min-height: 100vh;
  }

  .layout__header-wrapper {
    @include z-index(sticky, header);
    @include transition;

    top: 0;
    grid-row: 1/2;
    grid-column: 1/-1;

    &--hide {
      transform: translateY(-100%);
    }
  }

  .layout__header {
    @include transition;

    &--hide {
      transform: translateY(-101%);
    }
  }

  .layout__subscription-header-banner {
    @include transition;

    position: sticky;
    top: 0;
    z-index: 3;

    &--transform {
      transform: translateY(-70px);
    }
  }

  .layout__content {
    grid-row: 2/3;
    grid-column: 1/-1;
  }

  .layout__breadcrumbs.ab-ui-breadcrumbs,
  .layout__breadcrumbs.ab-ui-back-button {
    @include ui-container(lg);

    @include media(md-max) {
      width: fit-content;
      padding: 20px;
    }

    @include media(md, md-max) {
      padding-right: 32px;
      padding-left: 32px;
    }

    @include media(lg) {
      padding-top: 48px;
      padding-bottom: 16px;
    }
  }

  .layout__footer {
    grid-row: -2/-1;
    grid-column: 1/-1;
  }

  .layout__navigation {
    @include z-index(fixed, navigation);

    bottom: 0;
    width:100%;
  }

  .layout:has(.subscription-page) .layout__footer {
    @include media(md-max) {
      padding-bottom: 180px;
    }
  }
</style>
