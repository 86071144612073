import { defineStore } from 'pinia'
import type { Breadcrumb } from '@academica-box/components/Blocks/UiBreadcrumbs/types'
import { useDirectionsStore } from '@academica-box/composables/store/directions'

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
  getters: {
    breadcrumbs(): Breadcrumb[] {
      const route = useRoute()
      const routesWithoutBreadcrumbs = ['/cert', '/login', '/auth', '/pro']

      if (routesWithoutBreadcrumbs.some(routeWithoutBreadcrumbs => route.path.startsWith(routeWithoutBreadcrumbs)
      )) {
        return []
      }

      const pathArray = route.path.split('/').filter(Boolean)

      const { directionsWithQuery } = useDirectionsStore()

      const result: {
        title: string;
        link: string;
      }[] = []

      if (pathArray.length > 0) {
        result.push({
          title: 'Главная',
          link: '/',
        })
      }

      if (route.params.slugs?.[0]) {
        result.push({
          title: directionsWithQuery[0]?.title || '',
          link: directionsWithQuery[0]?.link || '',
        })
      }

      if (route.params.slugs?.[1]) {
        const selectedDirectionsWithQuery = directionsWithQuery.find((direction) => {
          return direction.link.includes(route.params.slugs?.[0] || '')
        })

        result.push({
          title: selectedDirectionsWithQuery?.title || '',
          link: selectedDirectionsWithQuery?.link || '',
        })
      }

      if (route.params.vendor) {
        result.push({
          title: 'Партнёры',
          link: '/vendors/'
        })
      }

      return result
    }
  },
})
