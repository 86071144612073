<template>
  <UiTabBar
    :tabs="tabs"
    class="project-tab-bar__tab-bar"
    @click:tab="handleTabClick"
  />

  <UiProfileModalSmart
    v-if="isOpenProfile"
    @visible:user-menu="sendAcademicaShowBurgerMenu"
    @click:item="(value: string) => sendAcademicaClickBurgerMenu({link: value})"
    @click:theme="$emit('click:theme')"
  />

  <UiCatalogModalSmart
    v-if="isOpenCatalog"
    @click:section="isOpenCatalog = !isOpenCatalog"
    @click:courses="isOpenCatalog = !isOpenCatalog"
    @click:direction="(index: number) => sendAcademicaClickMenu('header', {
      selected_direction_id: directionsWithQuery[index]?.slug || '',
    })"
    @visible:navigation="sendAcademicaShowMenu('header', {})"
    @click:banner="(value: string) => sendAcademicaClickMenuBanner({link: value})"
    @visible:banner="sendAcademicaShowBanner({})"
  />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import type { Tab } from '@academica-box/components/Blocks/UiTabBar/types'

  import { getQueryFromRoute } from '@academica-box/helpers/filters'
  import { useDirectionsStore } from '@academica-box/composables/store/directions'
  import type { FiltersApiGetCoursesRequest, FiltersApiGetFiltersRequest } from '@academica-box/swagger/academgen'

  import UiTabBar from '@academica-box/components/Blocks/UiTabBar/UiTabBar.vue'
  import UiCatalogModalSmart from '@academica-box/components/Smarts/UiCatalogModal.smart/UiCatalogModal.smart.vue'
  import UiProfileModalSmart from '@academica-box/components/Smarts/UiProfileModal.smart/UiProfileModal.smart.vue'

  import { useCoursesStore } from '@/store/courses'
  import { useFiltersStore } from '@/store/filters'

  import {
    sendAcademicaShowBurgerMenu,
    sendAcademicaClickBurgerMenu,
    sendAcademicaShowMenu,
    sendAcademicaClickMenu,
    sendAcademicaShowBanner,
    sendAcademicaClickMenuBanner,
  } from '@/pages/analytics'

  const emit = defineEmits([
    'click:tab',
    'click:theme',
  ])

  const route = useRoute()

  const coursesStore = useCoursesStore()
  const filtersStore = useFiltersStore()
  const directionsStore = useDirectionsStore()
  const { directionsWithQuery } = storeToRefs(directionsStore)

  const isOpenCatalog = ref(false)
  const isOpenProfile = ref(false)

  const tabs = computed(() => {
    return [
      {
        link: '/',
        title: 'Академика',
        isActive: route.path === '/' && !isOpenCatalog.value && !isOpenProfile.value,
        icon: 'ui-home'
      },
      {
        link: '/catalog/',
        action: 'open-catalog',
        isActive: (route.path.startsWith('/catalog') || isOpenCatalog.value) && !isOpenProfile.value,
        title: 'Каталог',
        icon: 'ui-burger-search'
      },
      {
        link: '/vendors/',
        isActive: route.path === '/vendors/' && !isOpenCatalog.value && !isOpenProfile.value,
        title: 'Партнёры',
        icon: 'ui-university'
      },
      {
        link: '/profile-menu/',
        action: 'open-profile-menu',
        isActive: isOpenProfile.value,
        title: 'Профиль',
        icon: 'ui-user'
      },
    ]
  })

  const handleTabClick = (tab: Tab) => {
    if (tab.action === 'open-catalog') {
      isOpenProfile.value = false
      isOpenCatalog.value = !isOpenCatalog.value
    } else if (tab.action === 'open-profile-menu') {
      isOpenCatalog.value = false
      isOpenProfile.value = !isOpenProfile.value
    } else {
      isOpenProfile.value = false
      isOpenCatalog.value = false
    }

    emit('click:tab', tab)
  }

  const getCatalogData = () => Promise.all([
    coursesStore.getCourses({
      ...getQueryFromRoute(route),
      limit: 12,
    } as FiltersApiGetCoursesRequest),
    filtersStore.getFilters(getQueryFromRoute(route) as FiltersApiGetFiltersRequest),
  ])

  const updateAsyncData = async () => {
    directionsStore.setActiveDirection(route)
    directionsStore.setActiveSection(route)
    await getCatalogData()
  }

  await onServerPrefetch(async () => {
    await updateAsyncData()
  })

  await useAsyncData(async () => {
    await updateAsyncData()
  })

  onBeforeRouteUpdate((route) => {
    directionsStore.setActiveDirection(route)
    directionsStore.setActiveSection(route)
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiZIndex' as *;

  .project-tab-bar__tab-bar {
    @include z-index(navigation, tab-bar);

    background-color: var(--bg-main-color);
  }
</style>
